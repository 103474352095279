import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { TYPOGRAPHY } from '@junglescout/styles-library'

const collapsedStyles = css`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const BreadcrumbWrapper = styled.aside`
  display: flex;
  align-items: center;
  ${TYPOGRAPHY.bodySm};
  font-weight: 500;
`
const Crumb = styled.span`
  ${collapsedStyles}
`
const Label = styled.span`
  ${collapsedStyles};
  margin-right: 5px;
  color: ${props => props.theme.colors.grey600};
`

function Breadcrumb({ items, className }) {
  const breadcrumbLength = items?.length

  if (!breadcrumbLength) {
    return null
  }

  return (
    <BreadcrumbWrapper className={className}>
      {items.map((item, index) => {
        // Render last item as text
        if (index === breadcrumbLength - 1) {
          return (
            <Crumb key={item.label} title={item.label}>
              {item.label}
            </Crumb>
          )
        }
        return (
          <NavLink key={item.label} to={item.url}>
            <Label title={item.label}>{item.label} / </Label>
          </NavLink>
        )
      })}
    </BreadcrumbWrapper>
  )
}

Breadcrumb.defaultProps = {
  className: undefined
}
Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  className: PropTypes.string
}
export { Breadcrumb }
