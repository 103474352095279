import React from 'react'
import { Navigate } from 'react-router-dom'
import { AuthRoute } from '../AuthRoute/AuthRoute'

const Empty = () => <div />

export const AdminRoute = ({ component: Component, ...props }) => {
  const {
    initialLoad,
    employeeWithAccess,
    isImpersonating,
    hasAuthy
  } = props.globalData

  if (initialLoad.isLoading) {
    return <Empty />
  }

  if (!employeeWithAccess || isImpersonating) {
    return <Navigate to={props.sessions.locationHistory} />
  }

  if (process.env.NODE_ENV === 'production' && !hasAuthy) {
    props.createNotification({
      message: 'Please enable authy to access the admin panel',
      level: 'error',
      title: 'Two-Factor Authentication Required'
    })
    return <Navigate to="/account/info" />
  }

  return <AuthRoute {...props} component={Component} />
}
