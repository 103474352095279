import React from 'react'
import PropTypes from 'prop-types'

import { DynamicImport } from '../../DynamicImport'

/**
 * This route provides a wrapper to load routes dynamically (code splitting)
 * @param {} param0
 */
export const DynamicRoute = ({ load, ...rest }) => {
  return load ? <DynamicImport load={load} {...rest} /> : null
}

DynamicRoute.defaultProps = {
  load: undefined
}

DynamicRoute.propTypes = {
  load: PropTypes.func
}
