import React, { useState, useRef, useEffect, useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import {
  Button,
  DropdownMenu,
  Popover,
  ButtonType,
  ImageStack,
  Text,
  FlagIcon,
  Separator
} from '@junglescout/edna'

import { openURL } from 'helpers/url'
import { amazonUrl } from 'helpers/amazon'
import { copyToClipboardAction } from 'helpers/clipboard'
import { formatImageResolution } from 'helpers/images'
import { sendCtaClickEvent } from 'helpers/segment'

export const slideIn = keyframes`
  0% {
    transform: scale(0.5);
    transform-origin: var(--radix-popover-content-transform-origin);
    opacity: 0%;
  }
  100% {
    transform: scale(1);
    transform-origin: var(--radix-popover-content-transform-origin);
    opacity: 100%;
  }
`

const StyledPopover = styled(Popover)`
  border-radius: 8px;
  &[data-state='open'] {
    animation: ${slideIn} 200ms ease-in-out;
  }
`
const Trigger = styled.div`
  cursor: default;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
`

const ProductSection = styled.div`
  padding: 12px;
  display: flex;
  gap: 12px;
`
const AsinAndBrand = styled.div`
  display: flex;
`
const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ActionsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 8px;
  padding: 12px;
  background-color: ${props => props.theme.colors.grey050};
  border-top: 1px solid ${props => props.theme.colors.grey100};
`

const EXIT_DELAY = 120 // (ms) delay prevents the popover from closing when briefly leaving the trigger to enter the popover

export const AsinActionsHoverCard = memo(
  ({
    asin,
    brand,
    imageUrl,
    marketplace,
    title,
    trigger,
    disabledActionsKeys,
    moreDropdownItems,
    openDelay,
    trackingProps,
    closeMenu,
    onOpenChange,
    overrideTriggerClick
  }) => {
    const triggerRef = useRef()
    const timeoutRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const { t } = useTranslation(['generic'])

    useEffect(() => {
      const handleInnerClick = e => {
        e.stopPropagation() // Prevent the popover click event from bubbling up to the parent elements
      }

      const innerElement = triggerRef.current
      if (innerElement && overrideTriggerClick) {
        innerElement.addEventListener('click', handleInnerClick)
      }

      return () => {
        if (innerElement && overrideTriggerClick) {
          innerElement.removeEventListener('click', handleInnerClick)
        }
        clearTimeout(timeoutRef.current) // Clean up the timeout on component unmount
      }
    }, [triggerRef, overrideTriggerClick])

    const formattedImageUrl = useMemo(() => formatImageResolution(imageUrl), [
      imageUrl
    ])

    const formattedMarketplace = marketplace.toLowerCase()

    const segmentTrackingProps = {
      extraProps: {
        section: 'Global ASINs Menu'
      },
      ...trackingProps
    }

    const handleOpenChange = open => {
      if (!open) {
        setMenuOpen(false)
      }
      setIsOpen(open)
      onOpenChange(open)
    }

    const handleMouseEnter = () => {
      clearTimeout(timeoutRef.current) // clear any existing timeout
      timeoutRef.current = setTimeout(() => {
        if (!isOpen && overrideTriggerClick) {
          triggerRef.current?.click()
        }

        handleOpenChange(true)
      }, openDelay)
    }

    const handleMouseLeave = () => {
      clearTimeout(timeoutRef.current) // clear any existing timeout
      timeoutRef.current = setTimeout(() => {
        if (!isOpen && overrideTriggerClick) {
          triggerRef.current?.click()
        }

        handleOpenChange(false)
        setMenuOpen(false)
      }, EXIT_DELAY)
    }

    const handleMouseDown = e => {
      if (overrideTriggerClick) {
        e.currentTarget.parentElement.click() // allow click events on the parent element
      }
    }

    const handleCopyAction = e => {
      e.stopPropagation() // prevent event from bubbling up to the trigger
      e.preventDefault() // prevent dropdown from closing after clicking
      sendCtaClickEvent({
        ...segmentTrackingProps,
        text: 'Copy ASIN',
        type: 'button'
      })
      copyToClipboardAction({
        e,
        text: asin,
        targetClassName: 'copy-asin-action',
        successText: t('generic:CopyAsin.isCopied', 'ASIN copied!')
      })
    }

    const globalActions = [
      {
        key: 'productDatabase',
        label: t(
          'generic:SearchInProductDatabase',
          'Search in Product Database'
        ),
        action: () => {
          sendCtaClickEvent({
            ...segmentTrackingProps,
            text: 'Search in Product Database',
            type: 'link'
          })
          openURL(
            `/#/database?marketplace=${marketplace}&include_keywords=${asin}`
          )
        }
      },
      {
        key: 'supplierDatabase',
        label: t('generic:FindSupplier', 'Find Supplier'),
        action: () => {
          sendCtaClickEvent({
            ...segmentTrackingProps,
            text: 'Find Supplier',
            type: 'link'
          })
          openURL(`/#/supplier?term=${asin}&type=asin`)
        }
      },
      {
        key: 'searchKeywords',
        label: t('generic:ViewKeywords', 'View Keywords'),
        action: () => {
          sendCtaClickEvent({
            ...segmentTrackingProps,
            text: 'View Keywords',
            type: 'link'
          })
          openURL(`/#/keyword?marketplace=${formattedMarketplace}&asin=${asin}`)
        }
      },
      {
        key: 'reviewAnalysis',
        label: t('generic:AnalyzeReviews', 'View in Review Analysis'),
        action: () => {
          sendCtaClickEvent({
            ...segmentTrackingProps,
            text: 'View in Review Analysis',
            type: 'link'
          })
          openURL(
            `/#/toolbox/review-analysis?marketplace=${formattedMarketplace}&asin=${asin}`
          )
        }
      }
    ]

    return (
      <StyledPopover
        open={isOpen && !closeMenu}
        onOpenChange={handleOpenChange}
        width="480px"
        content={
          <Content
            onClick={e => e.stopPropagation()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <ProductSection>
              <ImageStack
                src={formattedImageUrl}
                size="xlarge"
                alt={title}
                showOnHover={false}
              />
              <Details>
                <Text
                  element="h3"
                  variant="bodySm"
                  color="grey900"
                  fontWeight={500}>
                  {title}
                </Text>
                <AsinAndBrand>
                  <FlagIcon country={formattedMarketplace} size="14px" />
                  <Text
                    margin="0 0 0 8px"
                    element="p"
                    variant="bodyXs"
                    color="grey600"
                    uppercase
                    fontWeight={500}>
                    {asin}
                  </Text>
                  {brand && (
                    <>
                      <Separator
                        orientation="vertical"
                        height="16px"
                        margin="0 12px"
                      />
                      <Text
                        element="p"
                        variant="bodyXs"
                        color="grey600"
                        fontWeight={500}>
                        {brand}
                      </Text>
                    </>
                  )}
                </AsinAndBrand>
              </Details>
            </ProductSection>
            <ActionsSection>
              <Button
                btnType={ButtonType.TERTIARY}
                iconName="AMAZON_CIRCLE"
                onClick={e => {
                  e.stopPropagation()
                  sendCtaClickEvent({
                    ...segmentTrackingProps,
                    text: 'View on Amazon',
                    type: 'link'
                  })
                  openURL(amazonUrl(asin, formattedMarketplace))
                }}>
                {t('generic:ViewOnAmazon', 'View on Amazon')}
              </Button>
              <Button
                btnType={ButtonType.TERTIARY}
                iconName="COPY"
                onClick={handleCopyAction}>
                <span className="copy-asin-action">
                  {t('generic:CopyAsin.copy', 'Copy ASIN')}
                </span>
              </Button>
              <DropdownMenu
                open={menuOpen}
                onOpenChange={setMenuOpen}
                width="250px"
                side="right"
                align="start"
                trigger={
                  <Button
                    btnType={ButtonType.TERTIARY}
                    iconName="MORE"
                    onClick={e => e.stopPropagation()}>
                    {t('generic:More', 'More')}
                  </Button>
                }>
                {moreDropdownItems}
                {globalActions.map(({ key, label, action }) => (
                  <DropdownMenu.Item
                    key={label}
                    iconName="LINK_EXTERNAL"
                    disabled={disabledActionsKeys?.includes(key)}
                    action={e => {
                      e.stopPropagation()
                      action()
                    }}>
                    {label}
                  </DropdownMenu.Item>
                ))}
                <DropdownMenu.Divider key="divider-actions" />
                <DropdownMenu.Item
                  key="view-on-amazon"
                  iconName="AMAZON_CIRCLE"
                  action={e => {
                    e.stopPropagation()
                    openURL(amazonUrl(asin, formattedMarketplace))
                  }}>
                  {t('generic:ViewOnAmazon', 'View on Amazon')}
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  key="copy-asin"
                  iconName="COPY"
                  action={handleCopyAction}>
                  <span className="copy-asin-action">
                    {t('generic:CopyAsin.copy', 'Copy ASIN')}
                  </span>
                </DropdownMenu.Item>
              </DropdownMenu>
            </ActionsSection>
          </Content>
        }>
        <Trigger
          ref={triggerRef}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          {trigger}
        </Trigger>
      </StyledPopover>
    )
  }
)

AsinActionsHoverCard.defaultProps = {
  openDelay: 300,
  disabledActionsKeys: [],
  moreDropdownItems: [],
  trackingProps: {},
  closeMenu: false,
  onOpenChange: () => {},
  overrideTriggerClick: true
}

AsinActionsHoverCard.propTypes = {
  asin: PropTypes.string.isRequired,
  brand: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  marketplace: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  trigger: PropTypes.node.isRequired,
  disabledActionsKeys: PropTypes.arrayOf(PropTypes.string), // List of disabled action keys
  moreDropdownItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.node, PropTypes.element]) // DropdownMenu.Label, DropdownMenu.Item, DropdownMenu.Divider
    ),
    PropTypes.node
  ]),
  openDelay: PropTypes.number, // Delay in ms before the popover opens when hovering. Useful to prevent the popover from opening when briefly hovering over the trigger
  trackingProps: PropTypes.shape({
    // Used only for Segment tracking purposes
    category: PropTypes.string, // ex: name of the feature
    location: PropTypes.string // ex: location within that feature
  }),
  closeMenu: PropTypes.bool,
  onOpenChange: PropTypes.func, // Callback function that returns current open state
  overrideTriggerClick: PropTypes.bool // Set to false only when the trigger have multiple nested click actions
}
