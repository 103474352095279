import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { isSellerDataAvailable } from 'actions/account/amazonSellerAccount'

import { Features } from 'constants/feature_names'

import { someFeatureEnabled } from 'helpers/features'

import { useFeatureFlag } from 'hooks/feature_flag'
import { getCookie } from 'services/cookies'

import { SellerDataUnavailablePage } from 'ui_elements/SellerDataUnavailablePage/SellerDataUnavailablePage'

import { DynamicImport } from '../../DynamicImport'
import LoadingPage from '../../LoadingPage'

const SellerDataUnavailablePageWrapper = styled(SellerDataUnavailablePage)`
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 62px 30px 30px;
`

const isFeatureDisabled = (feature, globalData) => {
  let featureEnabled = !feature

  if (feature) {
    const flagData = globalData ? globalData.flagData : {}
    const features = Array.isArray(feature) ? feature : [feature]
    featureEnabled = someFeatureEnabled(features, flagData)
  }
  return !featureEnabled
}

const AuthRoute = props => {
  const {
    component: Component,
    feature,
    globalData,
    load,
    reducers,
    sellerFeature,
    ...rest
  } = props

  const authenticated = getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)
  const { isLoading } = globalData.initialLoad || {}

  const [citusAvailable, setCitusAvailability] = useState(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const isSellerFeaturesMaintenance = useFeatureFlag(
    Features.SELLER_FEATURES_MAINTENANCE
  )

  useEffect(() => {
    let mounted = true
    async function checkCitusAvailability() {
      const result = await dispatch(isSellerDataAvailable())
      if (mounted) {
        setCitusAvailability(result)
      }
    }

    if (sellerFeature && authenticated) {
      checkCitusAvailability()
    }

    return () => {
      mounted = false
    }
  }, [authenticated, dispatch, sellerFeature, location.pathname])

  if (!authenticated) {
    const { pathname, search, hash } = location

    if (pathname !== '/login') {
      // if user is trying to get to a certain page but needs to login, then allow them to login
      // and then redirected them to the page they where trying to reach.

      if (window.location.search.includes('amazon_callback_uri')) {
        window.location.href = `${process.env.REACT_APP_CLIENT_LOGIN}/${window.location.search}`
      } else {
        window.location.href = `${process.env.REACT_APP_CLIENT_LOGIN}/?redirectRoute=${pathname}${hash}${search}`
      }

      return null
    }
  }

  // if the feature is disabled - redirect the user to a 404 page
  if (isFeatureDisabled(feature, globalData) && !isLoading) {
    return <Navigate to="/404" />
  }

  if (sellerFeature) {
    if (citusAvailable === null) {
      return <LoadingPage />
    }
    if (!citusAvailable || isSellerFeaturesMaintenance) {
      return <SellerDataUnavailablePageWrapper showJSIcon />
    }
  }

  if (Component) {
    return <Component {...props} />
  }

  if (load) {
    const routeProps = rest

    if (rest.noMatchProp) {
      delete routeProps.match
    }

    return (
      <DynamicImport
        load={load}
        reducers={reducers}
        location={location}
        navigate={navigate}
        {...routeProps}
      />
    )
  }

  return null
}

AuthRoute.defaultProps = {
  globalData: undefined
}
AuthRoute.propTypes = {
  globalData: PropTypes.objectOf(PropTypes.any)
}

export { AuthRoute }
