import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { parseQueryString } from 'helpers/url'
import { getCookie } from 'services/cookies'
import { DynamicImport } from '../../DynamicImport'

export const UnAuthRoute = ({
  component: Component,
  load,
  reducers,
  location,
  ...rest
}) => {
  const authenticated = getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)
  const { login, authy } = rest.sessions
  if (
    !authenticated ||
    login.isLoading ||
    login.completed ||
    authy.completed ||
    authy.isLoading
  ) {
    if (Component) {
      return <Component {...rest} />
    }

    if (load) {
      return (
        <DynamicImport
          location={location}
          load={load}
          reducers={reducers}
          {...rest}
        />
      )
    }

    const objQS = parseQueryString(location.search) || {}
    const redirectPath = objQS.redirectRoute || '/account'

    return <Navigate to={redirectPath} />
  }

  return <Navigate to="/login" />
}

UnAuthRoute.defaultProps = {
  location: {},
  load: undefined,
  reducers: []
}

UnAuthRoute.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  load: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  reducers: PropTypes.arrayOf(PropTypes.any)
}
